import stripTags from 'striptags'

export default {
    methods: {
        setInitialDocumentValues() {
            if (!window.doc) return

            let initialDoc = {
                title: window.doc.title ? window.doc.title : '',
            }

            if (window.doc.copy_of_document_id) {
                const filename =
                    Boolean(window.doc.filename) && window.doc.user_id === window.user?.id
                        ? stripTags(`Copy of ${window.doc.filename}`)
                        : null

                initialDoc = {
                    ...initialDoc,
                    filename,
                    entity: window.entity,
                }
            }

            if (window.user && window.doc.user_id !== window.user.id) {
                if (window.user?.documents?.length) {
                    let lastUserDoc = window.user.documents.slice(-1)[0]
                    initialDoc = {
                        ...initialDoc,
                        subject: lastUserDoc.subject,
                        grade_levels: lastUserDoc.grade_levels,
                    }
                }
            }

            this.$store.dispatch('document/setDocument', initialDoc)
        },
        setCursorPositionOnFocus(componentRef, inputLength) {
            const quill = this.$refs[componentRef]?.quill

            if (!quill) return

            this.$nextTick(() => {
                quill.setSelection(inputLength - 1, inputLength)
            })
        },
    },
}
